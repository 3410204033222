/* inter.css */
/* Font declarations for Inter */

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url('./Inter/Inter Web/Inter-Thin.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 100;
  src: url('./Inter/Inter Web/Inter-ThinItalic.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-ThinItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url('./Inter/Inter Web/Inter-ExtraLight.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 200;
  src: url('./Inter/Inter Web/Inter-ExtraLightItalic.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-ExtraLightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('./Inter/Inter Web/Inter-Light.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-Light.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  src: url('./Inter/Inter Web/Inter-LightItalic.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('./Inter/Inter Web/Inter-Regular.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  src: url('./Inter/Inter Web/Inter-Italic.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('./Inter/Inter Web/Inter-Medium.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  src: url('./Inter/Inter Web/Inter-MediumItalic.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('./Inter/Inter Web/Inter-SemiBold.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  src: url('./Inter/Inter Web/Inter-SemiBoldItalic.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('./Inter/Inter Web/Inter-Bold.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  src: url('./Inter/Inter Web/Inter-BoldItalic.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('./Inter/Inter Web/Inter-ExtraBold.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 800;
  src: url('./Inter/Inter Web/Inter-ExtraBoldItalic.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-ExtraBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('./Inter/Inter Web/Inter-Black.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-Black.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 900;
  src: url('./Inter/Inter Web/Inter-BlackItalic.woff2') format('woff2'),
       url('./Inter/Inter Web/Inter-BlackItalic.woff') format('woff');
}

/* Variable font */
@font-face {
  font-family: 'Inter var';
  font-style: normal;
  font-weight: 100 900;
  src: url('./Inter/Inter Web/Inter.var.woff2') format('woff2');
} 