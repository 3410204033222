/* Import Inter font */
@import url('./assets/fonts/inter.css');

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  height: 100svh;
}

/* Apply Inter to all HTML elements */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

* {
    box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mySwiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
}

.swiper-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.personalDocumentTypes {
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
  margin: '10px';
  cursor: pointer;
}

.personalDocumentTypes:hover {
  background-color: lightgray;
}

.personalDocumentList {
  list-style-type: disc;
  margin-left: 2em;
}

.box-display-container {
  width: 100%;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.listItem {
  margin-bottom: 0.5em;
}

.buttonDiv {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.photo-button {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  margin-bottom: 30px;
}

.photo-water-mark {
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  margin: auto;
  opacity: 0.2;
}

.webcamContainer {
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.circle {
  position: absolute;
  top: 20%;
  left: 20%;
  bottom: 20%;
  right: 20%;
  border-radius: 100%;
  background-color: #ffffff;
  opacity: 0;
}

.ring {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  border: 0.5em solid #ffffffbb;
  opacity: 0.8;
}

.photo-button .circle,
.photo-button .ring {
  transition: all 0.25s;
}

.photo-button:hover .circle {
  opacity: 0.8;
}

.photo-button:active .ring {
  opacity: 0.8;
}

.photo-button:active .circle {
  opacity: 0.5;
}

.reset-button {
  z-index: 1;
}