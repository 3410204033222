@media screen and (min-width: 768px) {
    .base-container-width {
        width: 40%;
        min-width: 690px;
    }
    .base-container-padding {
        padding: 50px;
    }
    .face-dimensions {
        min-height: 690px;
        min-width: 690px;
    }
    .otp-control-width input{
        width: 60px;
    }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
    .base-container-width {
        width: 65%;
        min-width: 480px;
    }
    .base-container-padding {
        padding: 20px;
    }
    .face-dimensions {
        min-height: 90vh;
        min-width: 50vh;
    }
    .otp-control-width input{
        width: 60px
    }
}

@media screen and (max-width: 500px) {
    .base-container-width {
        width: 90%;
        min-width: 300px;
    }
    .base-container-padding {
        padding: 20px;
    }
    .face-dimensions {
        min-height: 90vh;
        min-width: 50vh;
    }
    .otp-control-width input{
        width: 15%;
        max-width: 60px
    }
}

.title-level1 {
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
}
.title-level2 {
    font-family: 'Roboto', sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
}
.title-level3 {
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
}
.title-level4 {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
}
.subtitle-level1 {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
}
.subtitle-level2 {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
}
.subtitle-level3 {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
}
.subtitle-level4 {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
}
.description-level1 {
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
}
.description-level2 {
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
}
.description-level3 {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
}
.description-level4 {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
}
.description-level5 {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}
.description-level6 {
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
}
.bold {
    font-weight: 600;
}

.page-container {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.base-container {
    margin: auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #646464ab;
    box-shadow: #646464ab 0 2px 8px;
}

.background-light-gray {
    background-color: #dddddd;
}
.light-gray {
    color: #dddddd;
}
.gray {
    color: #555555;
}
.background-gray {
    background-color: #dddddd;
}
.green-color {
    color: #72A593;
}

.otp-control input,
.otp-control label {
    display: block;
    font-size: 18px;
    line-height: 20px;
    background-color: rgba(25, 36, 126, 0.02);
    text-align: center;
    color: #555555;
}

.otp-control input,
.otp-control select {
    border-radius: 4px;
    border: 1px solid #ccc;
    height: 40px;
}
.otp-control input:focus {
    outline: none;
    border-color: #363843;
    background-color: rgba(107, 109, 122, 0.07);
}
.otp-control input {
    font-weight: 400;
    padding: 0.4rem;
}

.form-control input,
.form-control label {
    display: block;
    font-size: 14px;
    line-height: 7px;
    background-color: rgba(25, 36, 126, 0.02);
}

.form-control input,
.form-control select {
    border-radius: 4px;
    border: 1px solid #ccc;
    max-width: 100%;
    height: 40px;
}
.form-control input:focus {
    outline: none;
    border-color: #363843;
    background-color: rgba(107, 109, 122, 0.07);
}
.form-control input {
    font-weight: 400;
    padding: 0.4rem;
    width: 100%;
}
.form-error{
    color: #b40e0e;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    margin-top: 3px;
    margin-bottom: 3px;
    padding: 0px;
}

.invalid input {
    border: 1px solid #b40e0e;
    background-color: #fceeee;
}

.invalid input:focus {
    border-color: #b40e0e;
    background-color:  #fbdddd;
}

/* Button Box */
.button {
    border: 1px solid #bfbfbfab;
    border-radius: 50px; 
    padding: 8px;
    text-align: center;
    cursor: pointer;
}
.box {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.5); 
}
.box:hover {
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.5);
    transform: translateY(-1px);
}

.link {
    font-weight: 400;
    text-decoration: underline;
}
.link:not([disabled]):hover {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}
.link[disabled] {
    text-decoration: none;
}

.no-border-container-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
}

.border-container-fullwidth{
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 2.5px 0;
    border-radius: 8px;
    border: 1px solid #bfbfbfab;
    width: 100%;
}

.no-border-container-fullwidth{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px 0;
}

.no-border-container-margin{
    display: flex;
    flex-direction: column;
    margin: 20px;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

.otp__notification-container {
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.otp__notification-link {
    text-align: center;
}